<template>
  <div class="mb-1">
    <b-row>
      <b-col class="d-flex mt-1" cols="5">
        <vs-radio
          v-model="lock.durationLock"
          :vs-value="section.value"
          class="pointer ml-1"
          color="#0B2772"
        >
          <div class="radio-label ml-1">
            {{ $t(section.label) }}
          </div>
        </vs-radio>
      </b-col>
      <b-col>
        <transition
          v-for="(el, iterator) in Object.keys(lock)"
          v-if="el === 'fromDate' || el === 'toDate'"
          :key="`key-${iterator}`"
          name="slide-fade"
        >
          <d-inline-calendar
            v-if="hasDate(el, section.value)"
            :model="lock[el]"
            :disabled-date="(date) => el === 'fromDate' ? date < beforeToday : date <= $moment(el === 'toDate' ? beforeToday : lock.fromDate)"
            @on:select="lock[el] = $event"
          />
        </transition>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {RADIO_SECTION} from "@/classes/doinsport/Lock";

export default {
  name: RADIO_SECTION,
  data: () => ({
    display: false,
    targetDate: null,
  }),
  props: {
    section: {
      type: Object,
      default: () => {
      }
    },
    lock: {
      type: Object,
      default: () => {
      }
    },
  },
  computed: {
    beforeToday() {
      return this.$moment().add('days', -1);
    },
  },
  methods: {
    hasDate(el, value) {
      if (el === 'fromDate') {
        if (value === 'between' && this.lock.durationLock === 'between') {
          return true;
        }

        return this.lock.durationLock === 'fromDate' && value === 'fromDate';
      } else {
        if (value === 'between' && this.lock.durationLock === 'between') {
          return true;
        }

        return this.lock.durationLock === 'toDate' && value === 'toDate';
      }
    }
  },
}
</script>
<style lang="scss" src="@lazy/client/details/lock/_radio-section.scss" scoped/>
