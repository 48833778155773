import { render, staticRenderFns } from "./RadioSection.vue?vue&type=template&id=f6d7caac&scoped=true&"
import script from "./RadioSection.vue?vue&type=script&lang=js&"
export * from "./RadioSection.vue?vue&type=script&lang=js&"
import style0 from "@lazy/client/details/lock/_radio-section.scss?vue&type=style&index=0&id=f6d7caac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6d7caac",
  null
  
)

export default component.exports